import {
	ADD_CONTINENT,
	ADD_COUNTRY,
	ADD_CITIES,
	HEADER_DATA,
	SELECTED_COUNTRIES,
	SET_EMAIL_DATA,
} from "../constants/action-types";
const intialState = null;
export const locationReducer = (state = intialState, { type, payload }) => {
	switch (type) {
		case ADD_CONTINENT:
			return { ...state, continent_data: payload };
		case ADD_COUNTRY:
			return { ...state, country_data: payload };
		case ADD_CITIES:
			return { ...state, city_data: payload };
		case HEADER_DATA:
			return { ...state, header_data: payload };
		case SELECTED_COUNTRIES:
			return { ...state, selected_countries: payload };
		case SET_EMAIL_DATA:
			return { ...state, email_data: payload };
		default:
			return state;
	}
};
