/* eslint-disable */
import React from "react";
import styles from "./index.module.css";
import plainIcon from "../../assests/images/ic_flight_24px.png";
import plainIconGray from "../../assests/images/ic_flight_24pxgray.png";
import { Link } from "react-router-dom";
const MultiSteps = ({ page }) => {
	return (
		<>
			<div className={`${styles.steps}`}>
				<Link to="/#">
					<h1
						className={
							page === "DESTINATION"
								? `${styles.stespheading}`
								: `${styles.stepsGray}`
						}
					>
						Destinations
						<br />
						<br />
						<img
							src={page === "DESTINATION" ? plainIcon : plainIconGray}
							className="inline-block w-14"
						/>
					</h1>
				</Link>
				<span className="mb-2 text-gray-400 multStepdots">
					........................................
				</span>
				<h1
					className={
						page === "Activity"
							? `${styles.stespheading}`
							: `${styles.stepsGray}`
					}
				>
					Activities <br />
					<br />
					<img
						src={page === "Activity" ? plainIcon : plainIconGray}
						className="inline-block w-14"
					/>
				</h1>
				<span className="mb-2 text-gray-400 multStepdots">
					........................................
				</span>
				<h1
					className={
						page === "TRIPSUMMARY"
							? `${styles.stespheading}`
							: `${styles.stepsGray}`
					}
				>
					Trip Summary <br />
					<br />
					<img
						src={page === "TRIPSUMMARY" ? plainIcon : plainIconGray}
						className="inline-block w-14"
					/>
				</h1>
				<span className="mb-2 text-gray-400 multStepdots">
					........................................
				</span>
				<h1
					className={
						page === "REPORT" ? `${styles.stespheading}` : `${styles.stepsGray}`
					}
				>
					View Report <br />
					<br />
					<img
						src={page === "REPORT" ? plainIcon : plainIconGray}
						className="inline-block w-14"
					/>
				</h1>
			</div>
		</>
	);
};

export default MultiSteps;
