import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";
export default function MainSlider({ slides }) {
	const [slide, setSlide] = useState([]);
	useEffect(() => {
		let newslides = slides.filter((slide) => slide.isMain).slice(0, 2);
		setSlide(newslides);
	}, [slides]);
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		rows: 3,
		slidesPerRow: 1,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 1,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<>
			<Slider
				dots={false}
				slidesToShow={3}
				slidesToScroll={3}
				autoplay={false}
				autoplaySpeed={3000}
				{...settings}
			>
				{slide.map((slide) => (
					<div className="slideWrapper relative" key={slide.id}>
						<div className="post-feature-image">
							<h2 className="absolute  text-base font-bold text-white left-9 HelveticaFont top-8 postblogstate text-white-global">
								{slide.type}
							</h2>
							<img
								loading="lazy"
								src={
									process.env.REACT_APP_API +
									slide?.photos[slide?.photos?.length - 1].path
								}
								className="rounded-md postFeature"
								alt="..."
								title={slide.name}
							/>
							<h2 className="absolute text-xl font-bold text-white HelveticaFont left-9 bottom-32 break-all posttitlepost text-white-global">
								{slide.name.substring(0, 30)}
							</h2>

							<p className="absolute font-normal text-txts HelveticaFont text-white left-9 top-72 break-all postShortDesc text-white-global">
								{slide.description.substring(0, 135) + ""}
							</p>
						</div>
					</div>
				))}
			</Slider>
		</>
	);
}
