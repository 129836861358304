import React, { useState, useEffect } from "react";
import Destinations from "../destinations";
import Activity from "../activity";
import TripSummary from "../tripSummary";
import Report from "../report";
import MultiStepsBg from "../../component/multiStepsBg";
import MultiSteps from "../../component/multiSteps";
import { useSelector } from "react-redux";
import Footer from "../../component/footer";

const Plan = () => {
	const [planPage, setPlanPage] = useState("DESTINATION");
	const pageState = useSelector((state) => state?.planPage);
	useEffect(() => {
		setPlanPage(pageState?.page);
	}, [pageState]);

	const RenderPage = () => {
		switch (planPage) {
			case "DESTINATION":
				return <Destinations />;
			case "Activity":
				return <Activity />;
			case "TRIPSUMMARY":
				return <TripSummary />;
			case "REPORT":
				return <Report />;
			default:
				return <Destinations />;
		}
	};
	return (
		<>
			<div className="multibg">
				<MultiStepsBg />
			</div>
			<div className="container m-auto md:py-0 sm:px-0">
				<MultiSteps page={planPage} />
			</div>
			{RenderPage()}
			<Footer />
		</>
	);
};
export default Plan;
