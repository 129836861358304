/* eslint-disable */
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MiniSlider from "../blogsSlider/miniSlider";
import MainSlider from "../blogsSlider/mainSlider";
import Footer from "../footer";

export default function Carousel({ blogs }) {
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		rows: 3,
		slidesPerRow: 1,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 1,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};
	return (
		<section className="bg-gray-200">
			<div className="container m-auto md:pb-16 md:pt-8 sm:px-4 postContainer">
				<div className="trending country">
					<div className="slider-wrapper">
						<h1 className="mb-4 ml-3 text-secondary otherHeadline font-medium text-3xl uppercase">
							Have you visited these places?
						</h1>
						<div className="md:grid md:grid-cols-3 sm:block gap-0">
							<div className="col-span-2 postArea">
								<MainSlider slides={blogs} />
							</div>
							<div className="md:my-0 sm:my-3">
								<Slider
									dots={false}
									slidesToShow={3}
									slidesToScroll={3}
									autoplay={false}
									autoplaySpeed={3000}
									{...settings}
								>
									{blogs.map(
										(slide, index) =>
											!slide?.isMain && <MiniSlider slide={slide} key={index} />
									)}
								</Slider>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</section>
	);
}
