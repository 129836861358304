import React from "react";
import "./loader.css";
import { useSelector } from "react-redux";
import loaderImage from "../../assests/images/plane/newplaneloader.gif";
export default function Loader() {
	const loaderstatus = useSelector(({ loader }) => loader);
	return (
		<>
			{loaderstatus && (
				<div className="loaderbackground">
					<img src={loaderImage} alt="loader_logo" className="loaderGif" />
				</div>
			)}
		</>
	);
}
