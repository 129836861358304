import axios from "axios";
const instance = axios.create({
	baseURL: process.env.REACT_APP_API + "api",
});

instance.interceptors.request.use({
	function(config) {
		const token = localStorage.getItem("userToken");
		if (token) {
			config.headers["x-auth-token"] = token;
		}
		return config;
	},
	function(error) {
		return Promise.reject(error);
	},
});

export default instance;
