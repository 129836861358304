import {
	ADD_CONTINENT,
	ADD_COUNTRY,
	ADD_CITIES,
	HEADER_DATA,
	LOADER,
	SELECTED_COUNTRIES,
	SET_EMAIL_DATA,
} from "../constants/action-types";
import { getLocation } from "../../../utils";

const getData = async (id) => {
	let data = await getLocation(id);
	return data;
};

export const locationAction = (continent) => async (dispatch) => {
	let data = await getData(continent.id);
	dispatch({ type: ADD_CONTINENT, payload: data });
};

export const countryAction = (countryData) => async (dispatch) => {
	dispatch({ type: ADD_COUNTRY, payload: countryData });
};
export const citiesAction = (citiesData) => async (dispatch) => {
	dispatch({ type: ADD_CITIES, payload: citiesData });
};
export const headerAction = (data) => async (dispatch) => {
	dispatch({ type: HEADER_DATA, payload: data });
};
export const loaderAction = (bool) => async (dispatch) => {
	dispatch({ type: LOADER, payload: bool });
};
export const selectedCountriesAction = (data) => async (dispatch) => {
	dispatch({ type: SELECTED_COUNTRIES, payload: data });
};

export const emailAction = (data) => async (dispatch) => {
	dispatch({ type: SET_EMAIL_DATA, payload: data });
};
