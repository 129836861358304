import React, { useState, useEffect } from "react";
import ModalBox from "../../component/modalBox";
import { setPlanPage } from "../redux/actions/planActions";
import {
	selectedCountriesAction,
	loaderAction,
} from "../redux/actions/locationAction";
import { useDispatch, useSelector } from "react-redux";
import { getActivityCountries } from "../../utils";
export default function TripSummary() {
	const storeData = useSelector(({ location }) => location);
	const [showModal, setShowModal] = useState(false);
	const [expandcard, setExpandCard] = useState([]);
	const handleClose = (status) => {
		if (status) {
			dispatch(setPlanPage("REPORT"));
			return setShowModal(false);
		}
		setShowModal(false);
	};
	const handleShow = () => setShowModal(true);
	const [countriesData, setCountriesData] = useState([]);
	const [citiesData, setCitiesData] = useState([]);
	const dispatch = useDispatch();
	const routeBack = async () => {
		dispatch(setPlanPage("Activity"));
	};
	useEffect(async () => {
		dispatch(loaderAction(true));
		if (storeData && storeData.city_data) {
			const citydata = storeData.city_data;
			const cityids = citydata.map((item) => item.id);
			let countries = await getActivityCountries(cityids);
			setCitiesData(citydata);
			setCountriesData(countries);
			dispatch(selectedCountriesAction(countries));
			dispatch(loaderAction(false));
		}
	}, []);
	const readMore = (id) => {
		let checkinc = expandcard.includes(id);
		if (checkinc) {
			let newexpandcard = expandcard.filter((item) => item !== id);
			return setExpandCard(newexpandcard);
		}
		let newexpandcard = [...expandcard, id];
		setExpandCard(newexpandcard);
	};
	return (
		<>
			<div className="container md:w-full sm:w-full px-5 py-2 mx-auto lg:px-32">
				<div>
					<h1 className="text-secondary textSize font-bold uppercase trip-summery_heading">
						Your Destinations
					</h1>
					<p className="text-gray-700 text-lg">
						Your selected destinations
						{/* ( description area) */}
					</p>
				</div>
			</div>
			<section className="overflow-hidden text-gray-700 mb-14">
				<div className="container w-full sm:w-full px-5 py-8 mx-auto lg:px-32">
					<div className="grid md:grid-cols-3 sm:grid-cols-1 gap-16 destination-page-boxes">
						{countriesData.map((country) => (
							<div className="..." key={country.id}>
								<div className="rounded overflow-hidden">
									<img
										loading="lazy"
										style={{ height: "200px" }}
										className="w-full"
										src={process.env.REACT_APP_API + country.photos[0].path}
										alt="..."
										title={country.name}
									/>
									<div className="px-6 py-4 bg-white">
										<div
											className="font-bold text-xl mb-2 uppercase summery_title"
											style={{ wordBreak: "break-all" }}
											title={country.name}
										>
											{country.name.length >= 20
												? country.name.substring(0, 20) + "..."
												: country.name}
										</div>
										<p
											className="text-gray-700 text-base scrollBar"
											style={{ height: "130px" }}
										>
											{expandcard.includes(country.id)
												? country.description
												: country.description.substring(0, 220)}
										</p>
										{country.description.length >= 220 && (
											<label
												onClick={() => readMore(country.id)}
												style={{ cursor: "pointer" }}
											>
												{expandcard.includes(country.id) ? "Less" : "Read More"}
											</label>
										)}
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</section>
			<div className="container md:w-full sm:w-full px-5 py-2 mx-auto lg:px-32">
				<div className="grid md:grid-cols-2 lg:grid-cols-2 sm:grid-cols-1 gap-16">
					<div className="justify-center">
						<h1 className="text-secondary textSize font-bold uppercase trip-summery_heading">
							Your Activities
						</h1>
						<p className="text-gray-700 text-lg">
							Your selected activities
							{/* ( description area) */}
						</p>
					</div>
				</div>
			</div>
			<section className="overflow-hidden text-gray-700 mb-14">
				<div className="container w-full sm:w-full px-5 py-8 mx-auto lg:px-32">
					<div className="grid md:grid-cols-2 sm:grid-cols-1 gap-16 activites-page-boxes">
						{citiesData.map((city) => (
							<div className="relative" key={city.id}>
								<div className="max-w-sm w-full lg:max-w-full lg:flex">
									<div className="h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden">
										<img
											className="w-full h-56 object-cover trip_image"
											title={city.name}
											src={process.env.REACT_APP_API + city.photos[0].path}
											alt="..."
										/>
									</div>
									<div
										className="border-r border-b border-l border-gray-200 lg:border-l-0 lg:border-t lg:border-gray-200 bg-white 
                            rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal fit-webkit-width"
									>
										<div>
											<div className="Activity_title">
												<h2
													className="text-gray-900 font-bold text-xl mb-2"
													style={{ wordBreak: "break-all" }}
													title={city.name}
												>
													{city.name.length >= 20
														? city.name.substring(0, 20) + "..."
														: city.name}
												</h2>
											</div>
											<p
												className="text-gray-700 text-base scrollBar"
												style={{ height: "130px" }}
											>
												{expandcard.includes(city.id)
													? city.description
													: city.description.substring(0, 250)}
											</p>
											{city.description.length >= 250 && (
												<label
													onClick={() => readMore(city.id)}
													style={{ cursor: "pointer" }}
												>
													{expandcard.includes(city.id) ? "Less" : "Read More"}
												</label>
											)}
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
					<div className="flex mt-12">
						<div className="md:w-full flex justify-end">
							<button
								className="shadow uppercase GrayBgButton  
              hover:bg-gray-400 hover:text-white mr-4 md:w-1/6
              sm:w-2/4 text-sm focus:shadow-outline
              focus:outline-none text-secondary 
              font-bold py-2 px-6 rounded trip-back-btn"
								type="button"
								onClick={routeBack}
							>
								Back
							</button>
							<button
								className="shadow uppercase primaryBG
                  GreenBgButton hover:bg-emerald-700
                  focus:shadow-outline text-lg md:w-1/6 sm:w-2/4
                  focus:outline-none
                text-white font-bold py-2 px-4 rounded trip-popup-btn"
								type="button"
								onClick={() => handleShow()}
							>
								Create itinerary
							</button>
						</div>
					</div>
				</div>
			</section>
			{showModal ? (
				<ModalBox showModal={showModal} handleClose={handleClose} />
			) : (
				<></>
			)}
		</>
	);
}
