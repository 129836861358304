import axios from "../api";

// Get Country By Search
export async function getLocationBySearch(
	country,
	search,
	continent = undefined
) {
	try {
		const { data } = await axios.get(
			`/locations?type=${country}&search=${search}&continent=${continent}`
		);
		return data;
	} catch (err) {
		console.log(err);
	}
}

// Get Location By Type
export async function getLocationByType(country) {
	try {
		const { data } = await axios.get(`/locations?type=${country}`);
		return data;
	} catch (err) {
		console.log(err);
	}
}

// get location by id
export const getLocation = async (id) => {
	try {
		let { data } = await axios.get(`/location/${id}`);
		return data;
	} catch (err) {
		console.log(err);
		return {};
	}
};

// find include element in array
export const findElementInArray = (array, name, value) => {
	if (array && array.length > 0) {
		const ind = array.findIndex(
			(arr) => arr[name]?.toLowerCase() === value.toLowerCase()
		);
		return ind;
	}
};

// remove any element in array
export const removeItemFromArray = (array, setArray, name, value) => {
	if (array && array.length > 0) {
		const temp = [...array];
		const ind = findElementInArray(temp, name, value);
		if (ind > -1) {
			temp.splice(ind, 1);
			setArray(temp);
		}
	}
};

// get all city of country by country id
export const getCitiesByCountries = async (countriesIds, type) => {
	let apipayload = { countriesIds, type };
	try {
		let { data } = await axios.post("/location/countries/cities", apipayload);
		return data;
	} catch (err) {
		console.log(err);
		return {};
	}
};

// validation on countryname cityname
export const validateName = (name) => {
	let isValid = true;
	let msg = "";
	if (!name) {
		isValid = false;
		msg = "This field is required";
	} else if (name && name.length === 0) {
		isValid = false;
		msg = "Invalid Name";
	} else if (name && name.trim().length === 0) {
		isValid = false;
		msg = "Invalid Name";
	} else if (name && name.length > 50) {
		isValid = false;
		msg = "Field cannot have more than 50 Characters";
	} else if (name && name.length < 50) {
		let namereg = /^[A-Z a-z]+$/;
		isValid = namereg.test(name);
		if (!isValid) {
			msg = "Name Only Contains Characters";
		} else {
			msg = "";
		}
	} else {
		isValid = true;
		msg = "";
	}
	return { isValid, msg };
};

// validation on email address of users
export const validateEmail = (email) => {
	let msg = "";
	let isValid = true;
	let re =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (re.test(email)) {
		isValid = true;
		msg = "";
	} else {
		isValid = false;
		msg = "Invalid Email";
	}
	return { isValid, msg };
};

//validation for age should only number
export const validateAge = (age) => {
	let msg = "";
	let isValid = true;
	let re = /^\d*[1-9]\d*$/;
	if (age.length > 3) {
		isValid = false;
		msg = "Field cannot have more than 3 digits";
		return { isValid, msg };
	}
	if (re.test(age)) {
		isValid = true;
		msg = "";
	} else {
		isValid = false;
		msg = "Invalid Age";
	}
	return { isValid, msg };
};

//get createSummary data from this api
export async function createSummary(data) {
	try {
		const resp = await axios.post("/user/summary/create", data);
		return resp.data;
	} catch (err) {
		return { error: err.message };
	}
}

// get category by city id
export async function getCategory(citiesId) {
	try {
		const { data } = await axios.post("/activityVaccinePage", {
			locationid: citiesId,
		});
		return data;
	} catch (err) {
		console.log(err);
	}
}

// generate pdf link
export async function generatePDFLink(citiesid) {
	try {
		const { data } = await axios.post("generateReport", {
			locationid: citiesid,
		});
		return data;
	} catch (err) {
		console.log(err);
	}
}

// get country activity by city id
export async function getActivityCountries(citiesid) {
	let countries = [];
	try {
		const { data } = await axios.post("activityData", {
			activityIds: citiesid,
		});
		if (data.countries) {
			countries = data.countries;
		}
		return countries;
	} catch (err) {
		console.log(err);
	}
}

// send mail to user mail
export async function sendLinkToEmail(emaildata) {
	try {
		const { data } = await axios.post("share-report-mail", emaildata);
		return data;
	} catch (err) {
		return err.response.data;
	}
}

//get pdf url
export async function copyPDFURL(param) {
	try {
		const data = await axios.post("share-report", param);
		return data;
	} catch (err) {
		return err.response.data;
	}
}
