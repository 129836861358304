/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LocationIcon from "../../assests/images/ic_place_24px.png";
import { GrClose } from "react-icons/gr";
import { toast } from "react-toastify";
import { setPlanPage } from "../redux/actions/planActions";
import MapComponent from "../../component/Gmap";
import {
	countryAction,
	headerAction,
	citiesAction,
} from "../redux/actions/locationAction";
import ReactTooltip from "react-tooltip";

import { getLocationBySearch } from "../../utils";
export default function Destinations() {
	const dispatch = useDispatch();
	const storeData = useSelector(({ location }) => location);
	const [locationsData, setLocationsData] = useState([]);
	const [destinations, setDestinations] = useState([]);
	const [locationFields, setlocationFields] = useState({
		location: "",
		showSuggession: false,
	});
	const [mapData, setMapData] = useState([]);
	const [goBack, setGoBack] = useState(false);
	const [cordinates, setCordinates] = useState({ lat: 0, lng: 0 });
	const [expandcard, setExpandCard] = useState([]);
	const [disableredirect, setDisableRedirect] = useState(false);
	const handleRedirect = () => {
		if (destinations.length < 1) {
			setDisableRedirect(true);
			toast.error("Please Select Atleast One Location", {
				position: "top-center",
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				onClose: () => {
					setDisableRedirect(false);
					handleClose();
				},
			});
			return;
		}
		dispatch(countryAction(destinations));
		dispatch(
			headerAction({ ...storeData.header_data, selectedCities: destinations })
		);
		dispatch(setPlanPage("Activity"));
	};
	const handleBackRedirect = async () => {
		setGoBack(true);
	};
	useEffect(() => {
		const country_data = storeData?.country_data;
		if (country_data) {
			setDestinations(country_data);
			mapLocations(country_data);
		}
	}, []);
	useEffect(() => {
		const continent_data = storeData?.continent_data;
		const cor = {
			lat: continent_data?.latitude,
			lng: continent_data?.longitude,
		};
		setCordinates(cor);
	}, [storeData?.continent_data?.name]);
	const handleSelectChange = (event) => {
		const { value } = event.target;
		if (!value) return setlocationFields({});
		let newlocation = { ...locationFields };
		newlocation.location = value;
		newlocation.showSuggession = true;
		setlocationFields(newlocation);
		fetchSearchData(value);
	};
	const incrementFields = () => {
		if (locationFields.id) {
			let newdestinations = [...destinations, locationFields];
			setDestinations(newdestinations);
			mapLocations(newdestinations);
		}
		setlocationFields({});
	};
	const deleteDestination = (id) => {
		const city_data = storeData?.city_data;
		const newdestinations = destinations.filter((item) => item.id !== id);
		if (city_data.length >= 1) {
			dispatch(citiesAction([]));
		}
		setDestinations(newdestinations);
		mapLocations(newdestinations);
	};

	const fetchSearchData = async (search) => {
		const { locations } = await getLocationBySearch(
			"country",
			search,
			storeData?.continent_data.id
		);
		if (locations) {
			let filter_locations = locations.filter(
				(item) => !destinations.find((elem) => elem.name === item.name) && item
			);
			return setLocationsData(filter_locations);
		}
	};

	const mapLocations = (location) => {
		setMapData(location);
	};

	const selectSuggestion = (location) => {
		let newlocation = { ...location };
		newlocation.location = location.name;
		newlocation.showSuggession = false;
		setlocationFields(newlocation);
		setDisableRedirect(false);
	};

	const cleanField = () => {
		let newlocation = {};
		newlocation.location = "";
		newlocation.showSuggession = false;
		setlocationFields(newlocation);
	};

	const SuggestionsListComponent = () => {
		return (
			<ul className="suggestions w-11/12">
				{locationsData[0] ? (
					locationsData?.map((location) => (
						<li key={location.id} onClick={() => selectSuggestion(location)}>
							{location.name}
						</li>
					))
				) : (
					<li style={{ fontWeight: "bold" }}>No Record Found</li>
				)}
			</ul>
		);
	};
	if (goBack === true) {
		return <Navigate to="/" />;
	}
	const tootlTipText = "Delete";
	const readMore = (id) => {
		let checkinc = expandcard.includes(id);
		if (checkinc) {
			let newexpandcard = expandcard.filter((item) => item !== id);
			return setExpandCard(newexpandcard);
		}
		let newexpandcard = [...expandcard, id];
		setExpandCard(newexpandcard);
	};
	return (
		<>
			<div className="container md:w-11/12 m-auto px-4 desti-page-layout">
				<div className="container md:w-11/12 sm:w-full md:mx-auto sm:mx-4 sm:px-4 md:py-7 sm:py-0 desti-page-layout">
					<form
						className="w-full mx-auto max-w-7xl"
						onSubmit={(e) => e.preventDefault()}
					>
						<div className="flex items-center country-search-input">
							<div className="loactionicon">
								<label
									className="block text-gray-500 font-bold md:text-right mb-5 md:mb-4 pr-4 locationiconstyle"
									htmlFor="inline-full-name"
								>
									<img src={LocationIcon} className="w-8 object-cover" />
								</label>
							</div>
							<div className="md:w-full firstlocation relative items-center justify-between">
								<input
									type="text"
									onChange={(e) => handleSelectChange(e)}
									value={locationFields.location || ""}
									placeholder="Please enter a country"
									className="bg-white iunderstand adbothColor HelveticaFont 
                                    appearance-none border-0 border-gray-100 rounded 
                                    md:w-11/12 sm:w-full py-2 px-4 text-gray-700 leading-tight 
                                    focus:outline-none focus:bg-white focus:border-gray-200 mb-4 searchCountryInput"
								/>
								<button
									className=" absolute right-24 top-5 clear-btn"
									onClick={cleanField}
								>
									<GrClose style={{ fontWeight: "bold" }} />
								</button>
								{locationFields.showSuggession && <SuggestionsListComponent />}
							</div>
							<div className="add-btn-deskto-view">
								{destinations.length < 10 && (
									<button
										className="text-sm w-max shadow GreenBgButton
                                 uppercase hover:bg-emerald-700
                                 focus:shadow-outline focus:outline-none
                               text-white font-bold py-2 px-12 rounded mb-4"
										type="button"
										onClick={() => incrementFields()}
									>
										Add
									</button>
								)}
							</div>
						</div>
					</form>
					<div className="add-more-btn-mobile">
						{destinations.length < 10 && (
							<button
								className="flex m-auto text-sm w-max shadow GreenBgButton
                                 uppercase hover:bg-emerald-700
                                 focus:shadow-outline focus:outline-none
                               text-white font-bold py-2 px-12 rounded"
								type="button"
								onClick={() => incrementFields()}
							>
								Add
							</button>
						)}
					</div>
					<div className="country-description my-10">
						<p className="text-lg text-center font-semibold adbColor">
							Please enter the country or countries you will be visiting on this
							trip:
						</p>
					</div>
					<div className="mb-16">
						<div className="flex flex-col">
							<div
								className="relative w-full"
								style={{
									height: "500px",
								}}
							>
								<MapComponent locations={mapData} cordinates={cordinates} />
							</div>
						</div>
						<div className="societies flourished">
							{destinations.length >= 1 && (
								<h1 className="text-secondary font-30px text-3xl uppercase my-12">
									Trip Destinations
								</h1>
							)}
							<div className="grid md:grid-cols-3 sm:grid-cols-1 gap-16 destination-page-boxes">
								{destinations.map((item) => (
									<div
										className="..."
										key={item.id}
										style={{ cursor: "pointer" }}
									>
										<div
											className="rounded overflow-hidden  hoverforclose"
											style={{ position: "relative" }}
										>
											<button
												style={{ position: "absolute", right: 5, padding: 5 }}
												onClick={() => deleteDestination(item.id)}
												className="grclose"
											>
												<span>
													<GrClose />
												</span>{" "}
												<ReactTooltip />
											</button>
											<img
												style={{ height: "200px" }}
												className="w-full"
												src={
													process.env.REACT_APP_API +
													item.photos[item.photos.length - 1].path
												}
												alt="..."
												title={item.name}
											/>
											<div className="px-6 py-4 bg-white">
												<div
													className="font-bold mb-2 uppercase"
													style={{ wordBreak: "break-all" }}
												>
													{item.name}
												</div>
												<p
													className="text-gray-700 text-base scrollBar break-words"
													style={{ height: "100px" }}
												>
													{expandcard.includes(item.id)
														? item.description
														: item.description.substring(0, 180)}
												</p>
												{item.description.length >= 180 && (
													<label
														onClick={() => readMore(item.id)}
														style={{ cursor: "pointer" }}
													>
														{expandcard.includes(item.id)
															? "Less"
															: "Read More"}
													</label>
												)}
											</div>
										</div>
									</div>
								))}
							</div>
							<div className="flex mt-20 justify-evenly">
								<div className="w-full flex justify-end">
									<button
										className="shadow uppercase GrayBgButton  
                      hover:bg-gray-400 hover:text-white mr-4 md:w-1/6
                        sm:w-2/4 text-sm focus:shadow-outline
                        focus:outline-none text-secondary 
                        font-bold py-2 px-4 rounded"
										type="button"
										onClick={() => handleBackRedirect()}
									>
										Back
									</button>
									<button
										className="shadow uppercase 
                        GreenBgButton hover:bg-emerald-700
                        focus:shadow-outline text-sm md:w-1/6 sm:w-2/4 
                        focus:outline-none text-white font-bold py-2 px-4 rounded"
										type="button"
										disabled={disableredirect}
										onClick={() => handleRedirect()}
										style={
											disableredirect
												? { opacity: ".5", cursor: "no-drop" }
												: {}
										}
									>
										Next
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
