/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import exportIcon from "../../assests/images/ic_cloud_download_24px.png";
import shareIcon from "../../assests/images/ic_reply_24px.png";
import { getCategory, generatePDFLink, copyPDFURL } from "../../utils/index";
import { setPlanPage } from "../redux/actions/planActions";
import { loaderAction } from "../redux/actions/locationAction";
import { toast } from "react-toastify";
import Accordion from "../../component/Accordion";

export default function Report() {
	const [vaccinedata, setVaccineData] = useState();
	const [filename, setFileName] = useState();
	const [selectedcontinent, setSelectedContinent] = useState("");
	const [selectedcountries, setSelectedCountries] = useState([]);
	const [selectedcities, setSelectedCities] = useState([]);
	const [citiesid, setCitiesId] = useState([]);
	const storeData = useSelector(({ location }) => location);
	const [understand, setUnderStand] = useState(true);
	const dispatch = useDispatch();
	const handleUnderstand = (e) => {
		let status = !e.target.checked;
		setUnderStand(status);
	};

	// get pdf url and copy url to clipoboard
	const getPdfUrl = async () => {
		dispatch(loaderAction(true));
		setUnderStand(true);
		let itenaryid = storeData.email_data.itenaryId;
		let locationid = citiesid;
		let paramdata = { itenaryid, locationid };
		let data = await copyPDFURL(paramdata);
		dispatch(loaderAction(false));
		if (data.status === 200) {
			dispatch(loaderAction(false));
			navigator.clipboard.writeText(
				process.env.REACT_APP_API + "exports/" + data?.data?.filename
			);
			toast.success("URL Copied Successfully", {
				position: "top-center",
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				onClose: () => {
					setUnderStand(false);
				},
			});
			return;
		}
		toast.error("Error", {
			position: "top-center",
			autoClose: 2000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			onClose: () => {
				setUnderStand(false);
			},
		});
	};
	const getPdfFileName = async (citiesid) => {
		if (citiesid.length >= 1) {
			let { filename } = await generatePDFLink(citiesid);
			setFileName(filename);
		}
	};
	useEffect(async () => {
		dispatch(loaderAction(true));
		if (storeData.city_data) {
			const citiesid = storeData.city_data.map((item) => item.id);
			let res = await getCategory(citiesid);
			setSelectedCities(storeData.city_data);
			setVaccineData(res);
			setCitiesId(citiesid);
			getPdfFileName(citiesid);
		}
		if (storeData.continent_data) {
			setSelectedContinent(storeData.continent_data.name);
		}
		if (storeData.selected_countries) {
			setSelectedCountries(storeData.selected_countries);
		}
		dispatch(loaderAction(false));
	}, []);
	const handleBack = () => {
		dispatch(setPlanPage("TRIPSUMMARY"));
	};
	const generatePDF = () => {
		dispatch(loaderAction(true));
		setTimeout(() => {
			dispatch(loaderAction(false));
			if (filename)
				window.open(process.env.REACT_APP_API + "exports/" + filename);
		}, 2000);
	};
	return (
		<>
			<div className="container md:w-11/12 md:px-16 sm:w-full md:mx-auto sm:mx-4 sm: px-4 viewReportSec">
				<div className="grid md:grid-cols-2 lg:grid-cols-2 sm:grid-cols-1 md:gap-16 sm:gap-4 md:mt-24 sm:mt-4 report-page-boxes">
					<div className="justify-center text-sm">
						<h1 className="text-secondary textReportTitle font-bold uppercase md:mb-16 sm:mb-4 reportstyle">
							Your Report
						</h1>
						<div className="box-border border-2 px-4 borderColor bg-white">
							<h1 className="text-secondary text-xl leading-10 font-bold mt-4 mb-4">
								Continent:{" "}
								<span className="text-2xl text-secondary font-normal">
									{selectedcontinent}
								</span>
							</h1>
							<h1 className="text-secondary text-xl leading-10 font-bold mt-4 mb-4">
								Countries:{" "}
								{selectedcountries.map((country, index) => (
									<span
										className="text-2xl text-secondary font-normal"
										style={{ wordBreak: "break-all" }}
										key={country.id}
									>
										{country.name}
										{index < selectedcountries.length - 1 && ", "}
									</span>
								))}
							</h1>
							<h1 className="text-secondary text-xl leading-10 font-bold mt-4 mb-4">
								Activities:{" "}
								{selectedcities.map((city, index) => (
									<span
										className="text-2xl text-secondary font-normal"
										key={city.id}
										style={{ wordBreak: "break-all" }}
									>
										{city.name}
										{index < selectedcities.length - 1 && ", "}
									</span>
								))}
							</h1>
						</div>
						<div className="box-border border-2 px-4 mt-8 mb-8 pb-8 borderColor bg-white">
							<h1 className="textSize text-secondary leading-10 font-bold uppercase mt-8 mb-8">
								Recommended vaccinations
							</h1>
							<p className="text-2xl adbColor font-normal">
								These vaccinations are highly recommended in order to keep you
								safe and healthy abroad.
							</p>
							<div className="vaccine-title">
								{vaccinedata?.vaccineresult?.map((item) => (
									<p className="mt-6 mb-6" key={item.id}>
										<span className="text-primary text-3xl font-bold break-all vaccine-font">
											{item?.vaccine?.name}
										</span>
									</p>
								))}
							</div>
						</div>
						<div className="mb-8 report-term-desk">
							<div className=" justify-start">
								<p className="text-secondary font-medium termsCond">
									The following tool is for informational/educational purposes
									ONLY. TravelRx Education INC assumes no liability for these
									recommendations - as All Travelers must see a healthcare
									provider for specific advice pertaining to their travel
									vaccination needs at least 4-6 weeks prior to departure.
								</p>
								<div className="flex items-start mt-4">
									<div className="flex items-center h-5">
										<input
											aria-describedby="remember"
											type="checkbox"
											onChange={handleUnderstand}
											className="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 
                  focus:ring-blue-300 dark:bg-gray-600 dark:border-gray-500 
                  dark:focus:ring-blue-600 dark:ring-offset-gray-800 mt-4"
										/>
									</div>
									<div className="ml-3 text-sm">
										<label
											htmlFor="remember"
											className="font-medium adbColor iunderstand"
										>
											I understand
										</label>
									</div>
								</div>
							</div>
							<div className=" justify-start"></div>
							<div className="flex btn-group mt-4 mb-20">
								<div className="backBtnReport">
									<button
										className="w-36 shadow uppercase GreenBgButton 
                    hover:bg-emerald-700 hover:text-white focus:shadow-outline
                    focus:outline-none text-white exportbtn
                    font-bold py-3 px-4 rounded"
										type="button"
										onClick={handleBack}
									>
										Back
									</button>
								</div>
								<div className="export-btn  mx-4">
									<button
										className="w-36 shadow uppercase GreenBgButton 
                    hover:bg-emerald-700 hover:text-white focus:shadow-outline
                    focus:outline-none text-white exportbtn
                    font-bold py-3 px-4 rounded"
										type="button"
										onClick={generatePDF}
										disabled={understand}
										style={
											understand ? { opacity: "0.6", cursor: "no-drop" } : {}
										}
									>
										<img
											src={exportIcon}
											className="inline-block w-6 h-4 mr-2  m-auto"
										/>{" "}
										Export
									</button>
								</div>
								<div className="sharerepost">
									<button
										className="w-36 shadow uppercase GreenBgButton 
                      hover:bg-emerald-700 hover:text-white focus:shadow-outline
                      focus:outline-none text-white exportbtn
                      font-bold py-3 px-4 rounded"
										type="button"
										onClick={getPdfUrl}
										disabled={understand}
										style={
											understand ? { opacity: "0.6", cursor: "no-drop" } : {}
										}
									>
										Share{" "}
										<img
											style={{ transform: "scaleX(-1)" }}
											src={shareIcon}
											className="inline-block w-6 h-4  m-auto"
										/>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="justify-center vaccineDetails">
						{vaccinedata?.result?.map((item, index) => (
							<Accordion
								title={item?.category?.title}
								description={item?.category?.description}
								key={item.id}
							/>
						))}
					</div>
				</div>
				<div className="report-term-mobile">
					<div className=" justify-start">
						<p className="text-secondary font-medium termsCond">
							The following tool is for informational/educational purposes ONLY.
							TravelRx Education INC assumes no liability for these
							recommendations - as All Travelers must see a healthcare provider
							for specific advice pertaining to their travel vaccination needs
							at least 4-6 weeks prior to departure.
						</p>
						<div className="flex items-start mt-4">
							<div className="flex items-center h-5">
								<input
									aria-describedby="remember"
									type="checkbox"
									onChange={handleUnderstand}
									className="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 
                  focus:ring-blue-300 dark:bg-gray-600 dark:border-gray-500 
                  dark:focus:ring-blue-600 dark:ring-offset-gray-800 mt-4"
								/>
							</div>
							<div className="ml-3 text-sm">
								<label
									htmlFor="remember"
									className="font-medium adbColor iunderstand"
								>
									I understand
								</label>
							</div>
						</div>
					</div>
					<div className=" justify-start"></div>
					<div className="flex btn-group mt-4 mb-20">
						<div className="backBtnReport">
							<button
								className="w-44 shadow uppercase GreenBgButton 
                    hover:bg-emerald-700 hover:text-white focus:shadow-outline
                    focus:outline-none text-white exportbtn
                    font-bold py-3 px-4 rounded"
								type="button"
								onClick={handleBack}
							>
								Back
							</button>
						</div>
						<div className="export-btn  mx-4">
							<button
								className="w-44 shadow uppercase GreenBgButton 
                    hover:bg-emerald-700 hover:text-white focus:shadow-outline
                    focus:outline-none text-white exportbtn
                    font-bold py-3 px-4 rounded"
								type="button"
								onClick={generatePDF}
								disabled={understand}
								style={understand ? { opacity: "0.6", cursor: "no-drop" } : {}}
							>
								<img
									src={exportIcon}
									className="inline-block w-6 h-5 mr-2  m-auto"
								/>{" "}
								Export
							</button>
						</div>
						<div className="sharerepost">
							<button
								className="w-44 shadow uppercase GreenBgButton 
                      hover:bg-emerald-700 hover:text-white focus:shadow-outline
                      focus:outline-none text-white exportbtn
                      font-bold py-3 px-4 rounded"
								type="button"
								onClick={getPdfUrl}
								disabled={understand}
								style={understand ? { opacity: "0.6", cursor: "no-drop" } : {}}
							>
								Share{" "}
								<img
									style={{ transform: "scaleX(-1)" }}
									src={shareIcon}
									className="inline-block w-6 h-6  m-auto"
								/>
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
