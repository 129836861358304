import { ActionTypes } from "../constants/action-types";

const intialState = null;
export const planPageReducer = (state = intialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.PLAN_PAGE:
			return { ...state, page: payload };
		default:
			return state;
	}
};
