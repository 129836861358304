/* eslint-disable */
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "../../api/index";
import { useDispatch } from "react-redux";
import { loaderAction } from "../redux/actions/locationAction";
export default function TrendingCountry() {
	const [expandcard, setExpandCard] = useState([]);
	const dispatch = useDispatch();
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 1,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};
	const [trendingCountries, setTrendingCountries] = useState([]);
	useEffect(() => {
		fetchTrendingCountries();
	}, []);
	const fetchTrendingCountries = async () => {
		// alert()
		dispatch(loaderAction(true));
		try {
			const { data } = await axios.get("/trending/locations?type=country");
			const trendingcountries = data?.locations?.filter(
				(country) => country.trending
			);
			dispatch(loaderAction(false));
			setTrendingCountries(trendingcountries);
		} catch (err) {
			console.log(err);
		}
	};
	const readMore = (id) => {
		let checkinc = expandcard.includes(id);
		if (checkinc) {
			let newexpandcard = expandcard.filter((item) => item !== id);
			return setExpandCard(newexpandcard);
		}
		let newexpandcard = [...expandcard, id];
		setExpandCard(newexpandcard);
	};
	return (
		<section className=" bg-gray-200">
			<div className="container m-auto pt-16">
				<div className="trending country">
					<div className="slider-wrapper">
						<h1 className="ml-3 text-secondary font-medium text-3xl uppercase mb-4">
							Trending Countries
						</h1>
						<Slider
							dots={false}
							slidesToShow={
								trendingCountries.length > 3 ? 3 : trendingCountries.length
							}
							slidesToScroll={
								trendingCountries.length > 3 ? 3 : trendingCountries.length
							}
							autoplay={false}
							autoplaySpeed={3000}
							{...settings}
						>
							{trendingCountries.map((country) => (
								<div className="slick-slide" key={country.id}>
									<div className="slider Wapper image">
										<img
											loading="lazy"
											className="slick-slide-image"
											src={`${process.env.REACT_APP_API}${country.photos[0].path}`}
											title={country.name}
										/>
									</div>
									<div className="slider title">
										<h2
											className="slick-slide-title text-secondary HelveticaFont text-base font-bold"
											style={{ wordBreak: "break-all" }}
										>
											{country.name.substring(0, 20)}
										</h2>
									</div>
									<div
										className="slider description scrollBar"
										style={{ height: "100px" }}
									>
										<p
											className="slick-slide-label break-all"
											style={{
												fontFamily: "",
												fontSize: "16px",
												color: "#000",
											}}
										>
											{expandcard.includes(country.id)
												? country.description
												: country.description.substring(0, 70)}
										</p>
										{country.description.length >= 70 && (
											<label
												onClick={() => readMore(country.id)}
												style={{ cursor: "pointer" }}
											>
												{expandcard.includes(country.id) ? "Less" : "Read More"}
											</label>
										)}
									</div>
								</div>
							))}
						</Slider>
					</div>
				</div>
			</div>
		</section>
	);
}
