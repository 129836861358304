/* eslint-disable */
import React from "react";
import styles from "./index.module.css";
import siteLogo from "../../assests/logo/logo.png";
import countryIcon from "../../assests/images/Patfor.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function MultiStepsBg() {
	const location = useSelector(({ location }) => location);
	const headerdata = location?.header_data;
	const selectedcontries = location?.selected_countries;
	// function for se countryname and selected cities on banner image
	const headerCountry = (headerdata) => {
		let headercountries = [];
		if (selectedcontries) {
			headercountries = selectedcontries?.map((loc, index) => (
				<span key={loc?.id}>
					{loc?.name}
					{index < selectedcontries.length - 1 && ", "}
				</span>
			));
			return headercountries;
		}
		headercountries = headerdata?.selectedCities?.map((loc, index) => (
			<span key={loc?.id}>
				{loc?.name}
				{index < headerdata.selectedCities.length - 1 && ", "}
			</span>
		));
		return headercountries;
	};
	return (
		<section
			className={`${styles.BgSection}`}
			style={
				headerdata?.selectedContinent?.photos && {
					backgroundImage: `url(${
						process.env.REACT_APP_API +
						headerdata?.selectedContinent?.photos[
							headerdata?.selectedContinent?.photos?.length - 1
						]?.path
					})`,
				}
			}
		>
			<div className=" md:w-1/6 sm:w-full mb-32">
				<Link to="/">
					<img src={siteLogo} alt="Logo" className="p-4 logoResize" />
				</Link>
			</div>
			<h1 className={`${styles.cityTitle}`}>Travel Itinerary</h1>
			<div className={`${styles.deslocation}`}>
				<p className="font-bold text-txts">
					<img src={countryIcon} className="inline-block" />
					{"  "}
					<span>
						{headerdata?.selectedContinent?.name}
						{"  "}
					</span>
					{headerdata?.selectedCities && <span>&gt; {"  "}</span>}
					{headerCountry(headerdata)}
				</p>
			</div>
		</section>
	);
}
