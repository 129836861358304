/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPlanPage } from "../redux/actions/planActions";
import {
	citiesAction,
	headerAction,
	loaderAction,
	selectedCountriesAction,
} from "../redux/actions/locationAction";
import { toast } from "react-toastify";

import { getCitiesByCountries, removeItemFromArray } from "../../utils";

export default function Activity() {
	const dispatch = useDispatch();
	const storeData = useSelector(({ location }) => location);
	const [cities, setCities] = useState([]);
	const [selectedCities, setSelectedCities] = useState([]);
	const [expandcard, setExpandCard] = useState([]);
	const [disableredirect, setDisableRedirect] = useState(false);

	const handleSelect = (values, checked) => {
		setDisableRedirect(false);
		if (checked === true) {
			setSelectedCities([...selectedCities, values]);
		} else {
			removeItemFromArray(
				selectedCities,
				setSelectedCities,
				"name",
				values.name
			);
		}
	};
	useEffect(async () => {
		dispatch(selectedCountriesAction());
		dispatch(loaderAction(true));
		if (storeData && storeData.country_data) {
			const country_data = storeData.country_data;
			const ids = country_data.map((dt) => dt.id);
			const { result } = await getCitiesByCountries(ids, "city");
			if (result) {
				setCities(result);
			}
		}
		dispatch(loaderAction(false));
	}, []);
	useEffect(() => {
		if (storeData && storeData.city_data) {
			const city_data = storeData.city_data;
			if (city_data && city_data.length > 0) {
				setSelectedCities(city_data);
			}
		}
	}, [storeData]);
	const handleRedirect = () => {
		let selectedCitieslength = selectedCities.length;
		if (selectedCitieslength < 1) {
			setDisableRedirect(true);
			toast.error("Please Select Atleast One Activity", {
				position: "top-center",
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				onClose: () => {
					setDisableRedirect(false);
					handleClose();
				},
			});
			return;
		}
		dispatch(citiesAction(selectedCities));
		dispatch(setPlanPage("TRIPSUMMARY"));
	};
	const handleBackRedirect = () => {
		const continent = storeData?.continent_data;
		dispatch(setPlanPage("DESTINATION"));
		dispatch(headerAction({ selectedContinent: { ...continent } }));
	};
	const readMore = (id) => {
		let checkinc = expandcard.includes(id);
		if (checkinc) {
			let newexpandcard = expandcard.filter((item) => item !== id);
			return setExpandCard(newexpandcard);
		}
		let newexpandcard = [...expandcard, id];
		setExpandCard(newexpandcard);
	};
	return (
		<>
			<div className="container px-5 py-2 mx-auto lg:pt-12 lg:px-32">
				<div className=" ">
					<p className="text-lg text-center font-semibold text-secondary">
						Please select all the activities you will be doing on your trip from
						below:
					</p>
				</div>
			</div>
			<section className="overflow-hidden text-gray-700 mb-14">
				<div className="container px-5 py-2 mx-auto lg:pt-12 lg:px-32 activity_heading">
					{cities.map((location) => (
						<div key={location.id}>
							<h1 className="text-secondary font-medium text-3xl uppercase my-12 break-all">
								{location.country}
							</h1>
							{location.activity.length >= 1 ? (
								<div className="grid md:grid-cols-2 sm:grid-cols-1 gap-16 activites-page-boxes">
									{location.activity.map((city) => (
										<div className="relative" key={city.id}>
											<div className="w-full sm:h-auto lg:flex">
												<div
													className="h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden"
													title="Woman holding a mug"
												>
													<img
														className="w-full object-cover conuntryImage"
														loading="lazy"
														style={{ height: "100%" }}
														title={city.name}
														src={
															process.env.REACT_APP_API + city.photos[0].path
														}
														alt="Avatar of Jonathan Reinink"
													/>
												</div>
												<div
													className="border-r border-b border-l border-gray-200 lg:border-l-0 lg:border-t lg:border-gray-200 bg-white 
                    rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal activtiesBoxes"
												>
													<div>
														<div className="flex items-center absolute right-4 checkarea h-5">
															<div className="bg-white w-6 h-6 rounded-sm hidden-input">
																<input
																	className="w-6 h-6"
																	type="checkbox"
																	checked={
																		selectedCities.find(
																			(ct) => ct.id === city.id
																		)
																			? true
																			: false
																	}
																	onChange={(e) =>
																		handleSelect(city, e.target.checked)
																	}
																/>
															</div>
														</div>
														<div className="Activity_title">
															<h2 className="text-gray-900 font-bold text-xl mb-2 break-all ">
																{city.name}
															</h2>
														</div>
														<p
															className="text-gray-700 text-base scrollBar"
															style={{ height: "100px" }}
														>
															{expandcard.includes(city.id)
																? city.description
																: city.description.substring(0, 200)}
														</p>
														{city.description.length >= 200 && (
															<label
																onClick={() => readMore(city.id)}
																style={{ cursor: "pointer" }}
															>
																{expandcard.includes(city.id)
																	? "Less"
																	: "Read More"}
															</label>
														)}
													</div>
												</div>
											</div>
										</div>
									))}
								</div>
							) : (
								<div
									className="text-gray-700"
									style={{
										textAlign: "center",
										fontSize: "22px",
										color: "grey",
									}}
								>
									No Activity Found
								</div>
							)}
						</div>
					))}
					<div className="flex mt-20 justify-evenly">
						<div className="w-full flex justify-end">
							<button
								className="shadow uppercase GrayBgButton  
                      hover:bg-gray-400 hover:text-white mr-4 md:w-1/6
                      sm:w-2/4 text-sm focus:shadow-outline
                      focus:outline-none text-secondary 
                      font-bold py-2 px-4 rounded"
								type="button"
								onClick={() => handleBackRedirect()}
							>
								Back
							</button>
							<button
								className="shadow uppercase 
                      GreenBgButton hover:bg-emerald-700
                      focus:shadow-outline text-sm md:w-1/6 sm:w-2/4 focus:outline-none
                        text-white font-bold  py-2 px-4 rounded"
								type="button"
								onClick={() => handleRedirect()}
								disabled={disableredirect}
								style={
									disableredirect ? { opacity: ".5", cursor: "no-drop" } : {}
								}
							>
								Next
							</button>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
