/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import siteLogo from "../../assests/logo/logo.png";
import dropdownIcon from "../../assests/images/dropdown.png";
import { Link } from "react-router-dom";
import { setPlanPage } from "../redux/actions/planActions";
import { useDispatch, useSelector } from "react-redux";
import {
	locationAction,
	citiesAction,
	countryAction,
	headerAction,
	selectedCountriesAction,
} from "../redux/actions/locationAction";
import axios from "../../api";

const HeroSection = () => {
	const navigateMain = useNavigate();
	const dispatch = useDispatch();
	const storeData = useSelector(({ location }) => location);
	const [countries, setCountries] = useState([]);
	const [continent, setContinent] = useState({});
	const [continentErr, setContinentErr] = useState("");
	const handleRedirect = () => {
		if (!continent.id) {
			setContinentErr("This field is required");
			return;
		}
		dispatch(headerAction({ selectedContinent: { ...continent } }));
		dispatch(selectedCountriesAction());
		setContinentErr("");
		if (storeData && storeData.continent_data) {
			const data = storeData.continent_data;
			if (data.id !== continent.id) {
				dispatch(locationAction(continent));
				dispatch(citiesAction([]));
				dispatch(countryAction([]));
			}
		} else {
			dispatch(locationAction(continent));
			dispatch(citiesAction([]));
			dispatch(countryAction([]));
		}
		dispatch(setPlanPage("DESTINATION")).then(() => {
			navigateMain("/plan");
		});
	};
	useEffect(() => {
		fetchCountriesBySearch();
	}, []);

	const fetchCountriesBySearch = async () => {
		try {
			const { data } = await axios.get("/locations?type=continent");
			if (data.totalCount) return setCountries(data.locations);
		} catch (err) {
			console.log(err);
		}
	};
	const handleChange = (e) => {
		const { value } = e.target;
		if (value) {
			setContinentErr("");
		}
		let newdata = countries.filter((country) => country.name === value)[0];
		setContinent({ ...newdata });
	};

	return (
		<section className="hero-section-bg">
			<div className="md:w-1/6 sm:w-full logoSection">
				<Link to="">
					<img src={siteLogo} alt="Logo" className="p-4 logoResize" />
				</Link>
			</div>
			<div className="block pt-36 pb-20 md:pb-36 items-center md:flex">
				<div className="w-full">
					<div className="sectionWrapper">
						<div className="w-full left-0 right-0 m-auto top-1/2">
							<h1 className="text-center text-white font-semibold text-4xl uppercase text-white-global">
								Plan Your Trip
							</h1>
							<div className="flex justify-center">
								<div className="mb-3 mt-16 md:w-1/5 relative chooseCountry">
									<img
										loading="lazy"
										src={dropdownIcon}
										alt="Logo"
										className="absolute right-3 top-4"
									/>
									<div className="custom-select">
										<select
											className="form-select appearance-none
                        block w-full px-3 py-1.5 font-bold
                        text-secondary bg-white bg-clip-padding
                        bg-no-repeat  border border-solid border-gray-300
                        rounded transition ease-in-out m-0 focus:text-secondary 
                      focus:bg-white focus:border-blue-600 focus:outline-none textDecoration-safari"
											aria-label="Default select example"
											aria-labelledby="dropdownButton"
											onChange={(e) => {
												handleChange(e);
											}}
											value={continent?.name || "Select Continent"}
										>
											<option disabled>Select Continent</option>
											{countries?.map((country) => (
												<option
													key={country.id}
													value={country.name}
													className="OptVales"
												>
													{country.name}
												</option>
											))}
										</select>
										{continentErr && (
											<small
												style={{ fontSize: "14px" }}
												className="text-white"
											>
												{continentErr}
											</small>
										)}
									</div>
								</div>
							</div>
							<div className="text-center">
								<button
									className=" w-48 left-0 right-0
                             m-auto text-white
                             font-semibold text-sm p-2 mt-8 
                             uppercase GreenBgButton rounded-lg text-white-global"
									onClick={() => handleRedirect()}
								>
									Start planning
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default HeroSection;
