import { LOADER } from "../constants/action-types";
const initialstate = null;
export const loaderReducer = (state = initialstate, { type, payload }) => {
	switch (type) {
		case LOADER:
			return payload;
		default:
			return state;
	}
};
