import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import siteLogo from "../../assests/logo/logo.png";
import modelClose from "../../assests/images/modelClose.png";
import {
	validateName,
	validateEmail,
	createSummary,
	validateAge,
} from "../../utils";
import { emailAction } from "../../views/redux/actions/locationAction";

export default function ModalBox({ handleClose }) {
	const [data, setData] = useState({});
	const dispatch = useDispatch();
	const [validateErr, setValidateErr] = useState({});
	const [isAccepted, setIsAccepted] = useState(false);
	const storeData = useSelector(({ location }) => location);
	const [btndisable, setbtnDisable] = useState(false);
	const handleChange = (event) => {
		const { name, value } = event.target;
		setData({ ...data, [name]: value });
		validateErr[name] = "";
		setValidateErr({ ...validateErr });
		setbtnDisable(false);
	};
	const handleIsAcepted = (bool) => {
		if (bool) {
			validateErr.isAccepted = null;
		} else {
			validateErr.isAccepted = "Please accept the terms";
		}

		return setIsAccepted(bool);
	};
	const validation = () => {
		let valid = true;
		if (!data.name || data.name) {
			let { isValid, msg } = validateName(data.name);
			valid = isValid;
			validateErr.name = msg;
		}
		if (!data.email) {
			valid = false;
			validateErr.email = "This field is required";
		}
		if (data.email) {
			let { isValid, msg } = validateEmail(data.email);
			if (!isValid) {
				valid = false;
				validateErr.email = msg;
			}
		}
		if (data.age) {
			let { isValid, msg } = validateAge(data.age);
			if (!isValid) {
				valid = false;
				validateErr.age = msg;
			}
		}
		if (isAccepted === false) {
			valid = false;
			validateErr.isAccepted = "Please accept the terms";
		}
		setValidateErr({ ...validateErr });
		return valid;
	};
	const handleSubmit = async (e) => {
		e.preventDefault();
		let valid = validation();
		if (valid) {
			let summary = {};
			if (storeData && storeData.continent_data) {
				summary["continent_data"] = storeData.continent_data;
				if ("activities" in summary["continent_data"]) {
					summary["activities"] = summary["continent_data"]["activities"];
					delete summary["continent_data"]["activities"];
				}
			}
			if (storeData && storeData.country_data) {
				summary["country_data"] = storeData.country_data;
			}
			if (storeData && storeData.country_data) {
				summary["city_data"] = storeData.city_data;
			}
			const requestData = {
				summary,
				...data,
			};
			const res = await createSummary(requestData);
			if (res.id) {
				setbtnDisable(true);
				toast.success("Your form has been submitted successfully!", {
					position: "top-center",
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					onClose: () => {
						dispatch(emailAction({ email: data.email, itenaryId: res.id }));
						handleClose(true);
					},
				});
			} else if (res.error) {
				toast.error("Your form has not been submitted!", {
					position: "top-center",
					autoClose: 2000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			}
		}
	};
	return (
		<div
			className="flex justify-center pt-3 overflow-x-hidden 
          setModalBg overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
		>
			<div className="relative w-auto  mx-auto max-w-2xl">
				<div
					className="border-0 rounded-lg shadow-lg  top-52 flex flex-col
               w-full bg-white outline-none focus:outline-none"
				>
					<div className=" flex items-start justify-center p-5">
						<img className="pt-7" src={siteLogo} alt="site-logo" />
						<button
							className="bg-transparent border-0 text-black float-right right-4 absolute"
							onClick={() => handleClose()}
						>
							<span className="text-black opacity-7 h-6 w-6 text-xl block py-0 rounded-full">
								<img src={modelClose} alt="close-icon" />
							</span>
						</button>
					</div>
					<div className="relative md:px-6 sm:px-0 flex-auto">
						<form className="px-6 pb-4 space-y-6 lg:px-8 sm:pb-6 xl:pb-8">
							<h3 className=" model_heading text-2xl font-semibold popupDescri my-8">
								In order to see your report and share it with your friends
								please provide the following information
							</h3>
							<div className="formsetPro py-2">
								<label
									htmlFor="text"
									className="block mb-2 text-lg font-medium text-secondary dark:text-gray-300"
								>
									Name <span style={{ color: "red", fontSize: "14px" }}>*</span>
								</label>
								<input
									type="text"
									name="name"
									value={data.name || ""}
									id="text"
									className="border border-gray-300 text-gray-900 text-sm rounded-lg 
                        focus:ring-gray-500 focus:border-gray-500 block w-full p-1.5 dark:bg-gray-600 
                        dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
									onChange={(e) => handleChange(e, true)}
								/>
								{validateErr?.name && (
									<small style={{ color: "red", fontSize: "14px" }}>
										{validateErr?.name}
									</small>
								)}
							</div>
							<div className="formsetPro py-2">
								<label
									htmlFor="email"
									className="block mb-2 text-lg font-medium text-secondary dark:text-gray-300"
								>
									Email{" "}
									<span style={{ color: "red", fontSize: "14px" }}>*</span>
								</label>
								<input
									type="email"
									name="email"
									value={data.email || ""}
									id="email"
									className="border border-gray-300 text-gray-900 text-sm rounded-lg
                         focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-600
                          dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
									onChange={(e) => handleChange(e, true)}
								/>
								{validateErr?.email && (
									<small style={{ color: "red", fontSize: "14px" }}>
										{validateErr?.email}
									</small>
								)}
							</div>
							<div className="formsetPro py-2">
								<label
									htmlFor="number"
									className="block mb-2 text-lg font-medium text-secondary dark:text-gray-300"
								>
									Age
								</label>
								<input
									type="number"
									name="age"
									step="any"
									id="number"
									value={data.age || ""}
									className="border border-gray-300 text-gray-900 text-sm rounded-lg
                         focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-600 
                         dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
									onChange={(e) => handleChange(e, false)}
								/>
								{validateErr?.age && (
									<small style={{ color: "red", fontSize: "14px" }}>
										{validateErr?.age}
									</small>
								)}
							</div>
							<div className="formsetPro py-2">
								<label
									htmlFor="number"
									className="block mb-2 text-lg font-medium text-secondary dark:text-gray-300"
								>
									Gender
								</label>
								<select
									className="border HelveticaFont border-gray-300
                                text-gray-500 fontSizeUp rounded-md my-2 adbothColor
                                focus:ring-blue-500 focus:border-blue-500 block
                                  w-full p-2.5 dark:bg-gray-600 dark:border-gray-500
                                dark:placeholder-gray-400 dark:text-white mob-box-layout"
									onChange={(e) => handleChange(e, false)}
									name="gender"
									defaultValue={data.gender || "Select ..."}
								>
									<option disabled>Select ...</option>
									<option value="male">Male</option>
									<option value="female">Female</option>
									<option value="other">Other</option>
								</select>
								<p className="font-medium termsCond ">
									The following tool is for informational/educational purposes
									ONLY. TravelRx Education INC assumes no liability for these
									recommendations - as All Travelers must see a healthcare
									provider for specific advice pertaining to their travel
									vaccination needs at least 4-6 weeks prior to departure.
								</p>
							</div>
							<div className="flex justify-between m-0">
								<div className="flex items-start">
									<div className="flex items-center h-5">
										<div className="bg-white w-6 h-6 rounded-sm	hidden-input">
											<input
												id="remember"
												className="w-6 h-6"
												aria-describedby="remember"
												type="checkbox"
												onChange={(e) => handleIsAcepted(e.target.checked)}
											/>
										</div>
									</div>
									<div className="ml-3 text-sm">
										<label htmlFor="remember" className="font-medium termsCond">
											I Understand
										</label>
									</div>
								</div>
							</div>
							{validateErr?.isAccepted && (
								<small style={{ color: "red", fontSize: "14px" }}>
									{validateErr?.isAccepted}
								</small>
							)}
							<div className="flex justify-center">
								<button
									onClick={handleSubmit}
									disabled={btndisable}
									className="md:w-48 sm:w-3/4 text-white
                        GreenBgButton
                         hover:bg-emerald-700 uppercase 
                         font-semibold 
                         rounded-lg text-sm px-5 py-4 text-center 
                         dark:bg-blue-600 dark:hover:bg-blue-700 
                         dark:focus:ring-blue-800"
								>
									Sign up
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}
