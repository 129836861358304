import React from "react";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";

const Gmap = React.memo((props) => {
	const [cordinates, setCordinates] = React.useState({ lat: 0, lng: 0 });
	const [infomarker, setInfoMarker] = React.useState({});

	const style = {
		width: "100%",
		height: "100%",
	};
	const containerStyle = {
		width: "100%",
		height: "100%",
	};

	//function for thumbnail with country image and name
	const handleMarker = (mapprops, marker, e) => {
		let markerinfo = {
			selectedPlace: mapprops.name,
			activeMarker: marker,
			imgpath: mapprops.imgpath,
			showingInfoWindow: true,
		};
		let newinfomarker = { ...infomarker, ...markerinfo };
		setInfoMarker(newinfomarker);
	};
	React.useEffect(() => {
		setCordinates(props.cordinates);
	}, [props]);
	return (
		<Map
			google={props.google}
			style={style}
			containerStyle={containerStyle}
			zoom={2.7}
			center={cordinates}
		>
			{props.locations.map((location) => (
				<Marker
					key={location.id}
					name={location.name}
					imgpath={location.photos[location.photos.length - 1].path}
					onClick={handleMarker}
					position={{
						lat: location.latitude,
						lng: location.longitude,
					}}
				/>
			))}

			<InfoWindow
				marker={infomarker.activeMarker}
				visible={infomarker.showingInfoWindow}
			>
				<div style={{ fontSize: "18px" }}>
					<img
						width="275px"
						height="183px"
						src={process.env.REACT_APP_API + infomarker.imgpath}
						alt="..."
					/>
					<h1 style={{ padding: "5px", fontWeight: "Bold" }}>
						{infomarker.selectedPlace}
					</h1>
				</div>
			</InfoWindow>
		</Map>
	);
});
export default GoogleApiWrapper({
	apiKey: process.env.REACT_APP_GMAP_API_KEY,
})(Gmap);
