/* eslint-disable */
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./cdnfont.css";
import "./index.css";
import Landing from "./views/landing";
import Report from "./views/report";
import Plan from "./views/plan";
import Loader from "./component/loader/loader";
function App() {
	return (
		<>
			<Loader />
			<div>
				<ToastContainer
					position="top-right"
					autoClose={87}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
				<Router>
					<Routes>
						<Route path="/" exact element={<Landing />} />
						<Route path="/plan" exact element={<Plan />} />
						<Route path="/view-report" exact element={<Report />} />
					</Routes>
				</Router>
			</div>
		</>
	);
}
export default App;
