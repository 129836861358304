/* eslint-disable */
import React from "react";
export default function Footer() {
  return (
    <div>
      <footer className="bg-gray-200 text-center footer-bg">
        <div className="text-white text-center p-6">
          <div class="container mx-auto max-width-fit grid md:grid-cols-3 sm:grid-cols-1 gap-4">
            <div class="...">
              <a
                className="text-white"
                href="https://travelrx.ca/"
                target="_blank"
              >
                Back to www.travelrx.ca
              </a>
            </div>
            <div class="...">
              <a className="text-white" href="https://travelrx.ca/termsofuse" target="_blank">
                Terms of use
              </a>
              <a className="text-white ml-4" href="https://travelrx.ca/privacypolicy" target="_blank">
                Privacy Policy
              </a>
            </div>
            <div class="...">
              <a
                className="w-48 left-0 right-0
				m-auto text-white
				font-semibold text-sm p-3 mt-8 
				uppercase GreenBgButton rounded-lg text-white-global"
                href="https://intakeq.com/booking/ebntup?serviceId=0902d7dd-ef71-4032-93ca-99ea461549e4"
                target="_blank"
              >
                Book Consultation
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
