import { useState, useEffect } from "react";
import HeroSection from "./heroSection";
import TrendingCountry from "./trendingCountry";
import Carousel from "../component/carouselSlider";
import axios from "../api";
export default function Landing() {
	const [blogs, setBlogs] = useState([]);
	//data of current blog
	const fetchBlogs = async () => {
		const { data } = await axios.get("/currentblogs");
		setBlogs(data.blogs);
	};
	useEffect(() => {
		fetchBlogs();
	}, []);
	return (
		<>
			<div>
				<HeroSection />
				<TrendingCountry />
				<Carousel blogs={blogs} />
			</div>
		</>
	);
}
