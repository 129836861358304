export const ActionTypes = {
	PLAN_PAGE: "PLAN_PAGE",
};
export const ADD_CONTINENT = "ADD_CONTINENT";
export const ADD_LOCATION = "ADD_LOCATION";
export const ADD_COUNTRY = "ADD_COUNTRY";
export const ADD_CITIES = "ADD_CITIES";
export const HEADER_DATA = "HEADER_DATA";
export const LOADER = "LOADER";
export const SELECTED_COUNTRIES = "SELECTED_COUNTRIES";
export const SET_EMAIL_DATA = "SET_EMAIL_DATA";
