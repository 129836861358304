import React from "react";
export default function ({ slide }) {
	return (
		<div className="slick-slide postWarrap" key={slide.id}>
			<div className="slider Wapper image">
				<div className="slideWrapper">
					<div className="post-feature-image postFeatureImageWrapp relative">
						<h2 className="absolute  text-base font-bold text-white left-7 HelveticaFont top-12 postblogstate"></h2>
						<img
							className="slick-slide-image postFeatureImage"
							loading="lazy"
							src={
								process.env.REACT_APP_API +
								slide?.photos[slide?.photos?.length - 1].path
							}
							alt="..."
							title={slide.name}
						/>
					</div>
				</div>
			</div>
			<div className="slider title postTitleSlide">
				<h2 className="slick-slide-title text-secondary text-base font-bold break-words">
					{slide?.name?.length < 35
						? slide?.name
						: slide?.name?.substring(0, 35) + ""}
				</h2>
			</div>
		</div>
	);
}
