import { combineReducers } from "redux";
import { planPageReducer } from "./planPageReducer";
import { locationReducer } from "./locationreducer";
import { loaderReducer } from "./loaderreducer";
const reducers = combineReducers({
	planPage: planPageReducer,
	location: locationReducer,
	loader: loaderReducer,
});

export default reducers;
